
/* eslint-disable */
import CloudFun, { computed, defineComponent, ref, Condition, Operator, reactive, onMounted } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import CheckBoxList from '@/cloudfun/components/CheckBoxList.vue'
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import { getformOption, getType } from '@/libs/formItems';
import InvoiceQuarterlyItemTable from "@/components/document/InvoiceQuarterlyItemTable.vue";
import BOMUsageQuantityModifyItemTable from "@/components/document/BOMUsageQuantityModifyItemTable.vue";
import MaterialSubstitutesItemsTable from "@/components/document/MaterialSubstitutesItemsTable.vue";
import AssessmentItemsTable from "@/components/document/AssessmentItemsTable.vue";
import ProductNumberModifuItemsTable from "@/components/document/ProductNumberModifuItemsTable.vue";
import PurchaseRequisitionItemsTable from "@/components/document/PurchaseRequisitionItemsTable.vue";
import OrderItemsTable from "@/components/document/OrderItemsTable.vue";
import CustomerCreditItemsTable from "@/components/document/CustomerCreditItemsTable.vue";
import CheckRecordItemsTable from "@/components/document/CheckRecordItemsTable.vue";
import IQCItemsTable from "@/components/document/IQCItemsTable.vue";
import TypeAProductionCheckItemsTable from "@/components/document/TypeAProductionCheckItemsTable.vue";
import WinderItemsTable from "@/components/document/WinderItemsTable.vue";
import RollItemsTable from "@/components/document/RollItemsTable.vue";
import ProductionPackageQCItemsTable from "@/components/document/ProductionPackageQCItemsTable.vue";
import SalesItemsTable from "@/components/document/SalesItemsTable.vue";
import StaffItemsTable from "@/components/document/StaffItemsTable.vue";
import TrackingItemsTable from "@/components/document/TrackingItemsTable.vue";
import FinalQualityInspectionReportItemsTable from "@/components/document/FinalQualityInspectionReportItemsTable.vue";
import HumidityCheckRecordItemsTable from "@/components/document/HumidityCheckRecordItemsTable.vue";
import JobTickeDocumentItemsTable from "@/components/document/JobTickeDocumentItemsTable.vue";
import RemittanceSlipItemsTable from "@/components/document/RemittanceSlipItemsTable.vue";
import PerformanceEvaluationItemsATable from "@/components/document/PerformanceEvaluationItemsATable.vue";
import PerformanceEvaluationItemsBTable from "@/components/document/PerformanceEvaluationItemsBTable.vue";
import EntertainmentItemsTable from "@/components/document/EntertainmentItemsTable.vue";
import TravelItemsTable from "@/components/document/TravelItemsTable.vue";
import SampleNotificationFormItemsTable from "@/components/document/SampleNotificationFormItemsTable.vue";
import CategoryItemTable from "@/components/document/CategoryItemTable.vue";
import IQCTableItemsTable from "@/components/document/IQCTableItemsTable.vue";
import { formatAmount, formatResultTotalAmount } from '@/libs/utils'
import formatDate from 'xe-utils/toDateString';
import DocumentFileUpload from "@/components/file/DocumentFileUpload.vue";
import BusinessTripDailyReportItemsTable from "@/components/document/BusinessTripDailyReportItemsTable.vue";

export default defineComponent({
  components: {
    Grid,
    SelectBox,
    SalesItemsTable,
    StaffItemsTable,
    TrackingItemsTable,
    InvoiceQuarterlyItemTable,
    BOMUsageQuantityModifyItemTable,
    MaterialSubstitutesItemsTable,
    AssessmentItemsTable,
    ProductNumberModifuItemsTable,
    PurchaseRequisitionItemsTable,
    OrderItemsTable,
    CustomerCreditItemsTable,
    CheckRecordItemsTable,
    IQCItemsTable,
    TypeAProductionCheckItemsTable,
    WinderItemsTable,
    RollItemsTable,
    ProductionPackageQCItemsTable,
    FinalQualityInspectionReportItemsTable,
    HumidityCheckRecordItemsTable,
    JobTickeDocumentItemsTable,
    RemittanceSlipItemsTable,
    PerformanceEvaluationItemsATable,
    PerformanceEvaluationItemsBTable,
    EntertainmentItemsTable,
    TravelItemsTable,
    SampleNotificationFormItemsTable,
    CategoryItemTable,
    IQCTableItemsTable,
    DocumentFileUpload,
    BusinessTripDailyReportItemsTable,
  },
  setup () {
    const model = CloudFun.current?.model
    const grid = ref<any>({})
    var printColumns = [{ field: 'Name' }, { field: 'Description' }]
    const selectedType = ref<any>('');

    const user = CloudFun.current?.user;
    const policy = CloudFun.current?.policy;

    const checkPermissions = (key: string) => {
      // console.log(user?.Permissions)
      try {
        return user && policy?.isApproved(user, key);
      } catch {
        return false;
      }
    };

    const canCreate = checkPermissions("DocumentCreate");
    const canUpdate = checkPermissions("DocumentUpdate");
    const canDelete = checkPermissions("DocumentDelete");

    const gridOptions: GridOptions = {
      id: 'document',
      title: '建立文件申請',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      canCreate: canCreate,
      canUpdate: canUpdate,
      canDelete: canDelete,
      multiselect: false,
      printConfig: {
        sheetName: '清單',
        columns: printColumns,
        modes: ['current', 'selected', 'all']
      },
      exportConfig: {
        filename: '清單',
        type: 'csv',
        types: ['html', 'csv'],
        mode: 'all',
        modes: ['current', 'selected', 'all'],
        columns: printColumns
      },
      columns: [
        { field: 'Title', title: '名稱', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: "Type", title: "文件類型", showHeaderOverflow: true, showOverflow: true, formatter: ({ cellValue }) => model ? Object.values(model.enums.DocumentType).find(e => e.Value === cellValue)?.Name : undefined },
        { field: "ContentType", title: "表單類型", showHeaderOverflow: true, showOverflow: true, formatter: ({ cellValue }) => model ? Object.values(model.enums.DocumentContentType).find(e => e.Value === cellValue)?.Name : undefined },
        { field: 'Submitter.Person.Name', title: '申請人', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'CurrentSignatureLevel', title: '目前簽核層級', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'Status', title: '簽核狀態', showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => model ? Object.values(model.enums.DocumentStatus).find(e => e.Value === cellValue)?.Name : undefined },
        // {
        //   title: '文件流程',
        //   width: '100',
        //   slots: { default: "flow" }, align: 'center'
        // },
      ],
      promises: {
        query: model ? async (params) => {
          if (selectedType.value !== "") {
            params.condition!.and("ContentType", Operator.Equal, selectedType.value);
          }
          if (params?.sortings?.length === 0) {
            params.sortings = [{ column: 'CreatedTime', order: 1 }];
          }
          return await model.dispatch('document/query', params);
        } : undefined,
        queryAll: model ? () => model.dispatch('document/query') : undefined,
        save: model
          ? (params) => model.dispatch('document/save', params)
          : undefined
      },
      modalConfig: { width: "60%", showFooter: true, fullscreen: true }
    }

    // const contentTypeChange = (item: any) => {
    //   if (item.data.ContentType >= 0) {
    //     var contentTypeOption = getformOption(item.data.ContentType) as any;
    //     formOptions.items?.push({
    //       field: 'ApplicationDate',
    //       title: '填表(申請)日期',
    //       span: 12,
    //       itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期' } }
    //     })
    //     console.log(form.value)
    //     //Array.prototype.push.apply(formOptions.items, contentTypeOption.items);
    //     //Array.prototype.push.apply(formOptions.rules, contentTypeOption.items);
        
    //   }
    // }

    const form = ref<any>();
    const readType = ref(false);
    const defaultFormOptions: VxeFormProps = {
      items: [
        {
          field: 'Title',
          title: '名稱',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字' },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'SubmittingTime',
          title: '提交時間',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期' } }
        },
        {
          field: 'Type',
          title: '文件類型',
          span: 12,
          itemRender: { name: "$select", props: {}, options: model ? Object.values(model.enums.DocumentType).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        },
        {
          field: 'ContentType',
          title: '表單類型',
          span: 12,
          itemRender: { name: "$select", props: {}, options: model ? Object.values(model.enums.DocumentContentType).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        },
        {
          field: 'SubmitterId',
          title: '申請人',
          span: 12,
          slots: { default: "column-submitter" }
        },
        {
          field: 'CurrentSignatureLevel',
          title: '目前簽核層級',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '', type: 'number', disabled: true },
          }
        },
        {
          field: 'Status',
          title: '簽核狀態',
          span: 12,
          itemRender: { name: "$select", props: { disabled: true }, options: model ? Object.values(model.enums.DocumentStatus).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        },
      ],
      rules: {
        Title: [{ type: 'string', required: true }],
        SubmittingTime: [{ required: true }],
        Type: [{ required: true }],
        ContentType: [{ required: true }],
      }
    }

    const formOptions: VxeFormProps = {
      titleWidth: 100,
      titleAlign: 'right',      
    }

    const userSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Person.Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("employee/find", value), // eslint-disable-line
        query: params => {
          // params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          return model!.dispatch("employee/query", params) // eslint-disable-line
        }
      }
    };

    const departmentSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("department/find", value), // eslint-disable-line
        query: params => {
          // params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          return model!.dispatch("department/query", params) // eslint-disable-line
        }
      }
    };

    const initAddData = (row: any, callback: any) => {
      row.SubmitterId = user?.Id;
      row.SubmittingTime = new Date();

      row.Status = 0;
      readType.value = false;
      formOptions.items = defaultFormOptions.items;
      formOptions.rules = defaultFormOptions.rules;

      grid.value.isModalPopup = true;
      callback();
    }

    const setDefaultReadOnly = (data: any) => {
      data.items[0].itemRender.props.readonly = true;
      data.items[1].itemRender.props.readonly = true;
      data.items[2].itemRender.props.disabled = true;
      data.items[3].itemRender.props.disabled = true;
      return data;
    }

    const invoice = ref<any>();
    const packingList = ref<any>();
    const onGridEdit = async (row: any, callback: any) => {
      const entity = row.Id ? await model?.dispatch('document/find', row.Id) : undefined
      Object.assign(row, entity);
      if (row.Id) {
        readType.value = false;
        var contentTypeOption = getformOption(row.ContentType, model, row.Status == 0 ? false : true) as any;
        // console.log(contentTypeOption)
        let defaultCopy = JSON.parse(JSON.stringify(defaultFormOptions));
        if(row.Status != 0) { //唯讀
          readType.value = true;
          defaultCopy = setDefaultReadOnly(defaultCopy);
        }
        Array.prototype.push.apply(defaultCopy.items, contentTypeOption.items);
        for (const key in contentTypeOption.rules) {
          // console.log(key, contentTypeOption.rules[key])
          defaultCopy.rules[key] = contentTypeOption.rules[key];
        }
        formOptions.items = defaultCopy.items;
        formOptions.rules = defaultCopy.rules;

        if(row.ContentType == 26) {
          var year = new Date().getFullYear();
          var month = new Date().getMonth() + 1;
          if(!row.XmlData.Year)
            row.XmlData.Year = year;
          if(!row.XmlData.Month)
            row.XmlData.Month = month;
        }
        if(row.ContentType == 31) {
          invoice.value = row.XmlData.InvoiceId ? await model?.dispatch('invoice/find', row.XmlData.InvoiceId) : null;
          const condition = new Condition("InvoiceNumber", Operator.Equal, row.XmlData.InvoiceNumber);
          var list = row.XmlData.InvoiceNumber ? await model?.dispatch("packingList/query", { condition }): null;
          packingList.value = list?.[0];
        }
        if(row.ContentType == 47 || row.ContentType == 49) {
          if(fileModal) fileModal.show(row);
        }
        
        // console.log(row)
        // for (const key in row.XmlData) {
        //   formOptions.items?.push({
        //     field: key,
        //     title: key,
        //     span: 12,
        //     itemRender: {
        //       name: '$input',
        //       props: { placeholder: '請輸入文字' },
        //     }
        //   })
        // }
      }
      callback()
    }

    const itemsTable = ref<any>();
    const ProductModifyItemsTable = ref<any>();
    const FinishedProductModifyItemsTable = ref<any>();
    const ReRollModifyItemsTable = ref<any>();
    const MaterialSubstitutesItemsTable = ref<any>();
    const AssessmentItemsTable = ref<any>();
    const ProductNumberModifuItemsTable = ref<any>();
    const PurchaseRequisitionItemsTable = ref<any>();
    const OrderItemsTable = ref<any>();
    const CustomerCreditItemsTable = ref<any>();
    const CheckRecordItemsTable = ref<any>();
    const IQCItemsTable = ref<any>();
    const TypeAProductionCheckItemsTable = ref<any>();
    const WinderItemsTable = ref<any>();
    const RollItemsTable = ref<any>();
    const ProductionPackageQCItemsTable = ref<any>();
    const FinalQualityInspectionReportItemsTable = ref<any>();
    const SalesItemsTable = ref<any>();
    const StaffItemsTable = ref<any>();
    const TrackingItemsTable = ref<any>();
    const HumidityCheckRecordItemsTable = ref<any>();
    const JobTickeDocumentItemsTable = ref<any>();
    const RemittanceSlipItemsTable = ref<any>();
    const PerformanceEvaluationItemsATable = ref<any>();
    const PerformanceEvaluationItemsBTable = ref<any>();
    const EntertainmentItemsTable = ref<any>();
    const TravelItemsTable = ref<any>();
    const SampleNotificationFormItemsTable = ref<any>();
    const BusinessTripDailyReportItemsTable = ref<any>();
    
    const formSubmit = (row: any, submit: any) => {
      if(row.Id) {
        if(row.ContentType == 2 || row.ContentType == 4) { //國內外請款單
          var invoiceQuarterlyItems = itemsTable?.value.getData();
          // console.log(invoiceQuarterlyItems)
          if(invoiceQuarterlyItems)
            row.XmlData.InvoiceQuarterlyItems = invoiceQuarterlyItems;
        }
        if(row.ContentType == 1) { //BOM表異動申請
          //產品
          var ProductModifyItems = ProductModifyItemsTable?.value.getData();
          if(ProductModifyItems)
            row.XmlData.ProductModifyItems = ProductModifyItems;
          //成品
          var FinishedProductModifyItems = FinishedProductModifyItemsTable?.value.getData();
          if(FinishedProductModifyItems)
            row.XmlData.FinishedProductModifyItems = FinishedProductModifyItems;
          //覆卷
          var ReRollModifyItems = ReRollModifyItemsTable?.value.getData();
          if(ReRollModifyItems)
            row.XmlData.ReRollModifyItems = ReRollModifyItems;         
        }
        if(row.ContentType == 6) { //取替代料件異動申請表
          var MaterialSubstitutesItems = MaterialSubstitutesItemsTable?.value.getData();
          // console.log(invoiceQuarterlyItems)
          if(MaterialSubstitutesItems)
            row.XmlData.Items = MaterialSubstitutesItems;
        }
        if(row.ContentType == 10) { //人員考核月報表
          var invoiceQuarterlyItems = itemsTable?.value.getData();
          if(invoiceQuarterlyItems)
            row.XmlData.InvoiceQuarterlyItems = invoiceQuarterlyItems;
          var assessmentItems = AssessmentItemsTable?.value.getData();
          if(assessmentItems)
            row.XmlData.Items = assessmentItems;
        }
        if(row.ContentType == 11) { //品號異動申請單
          var productNumberModifuItems = ProductNumberModifuItemsTable?.value.getData();
          if(productNumberModifuItems)
            row.XmlData.Items = productNumberModifuItems;
        }
        if(row.ContentType == 12 || row.ContentType == 13) { //請購單 報價單
          var purchaseRequisitionItems = PurchaseRequisitionItemsTable?.value.getData();
          if(purchaseRequisitionItems)
            row.XmlData.Items = purchaseRequisitionItems;
        }
        if(row.ContentType == 14) { //客戶訂單
          var orderItems = OrderItemsTable?.value.getData();
          if(orderItems)
            row.XmlData.Items = orderItems;
        }
        if(row.ContentType == 15) { //客戶超限放行單
          var customerCreditItems = CustomerCreditItemsTable?.value.getData();
          if(customerCreditItems)
            row.XmlData.Items = customerCreditItems;
        }
        if(row.ContentType == 15) { //客戶超限放行單
          var customerCreditItems = CustomerCreditItemsTable?.value.getData();
          if(customerCreditItems)
            row.XmlData.Items = customerCreditItems;
        }
        if(row.ContentType == 16) { //巡檢表
          var checkRecordItems = CheckRecordItemsTable?.value.getData();
          if(checkRecordItems)
            row.XmlData.Items = checkRecordItems;
        }
        if(row.ContentType == 17 || row.ContentType == 18 || row.ContentType == 19 || row.ContentType == 20) { //來料檢驗
          var iQCItems = IQCItemsTable?.value.getData();
          if(iQCItems)
            row.XmlData.Items = iQCItems;
        }
        if(row.ContentType == 21) { //生產規格首件QC檢驗紀錄管理
          var typeAProductionCheckItems = TypeAProductionCheckItemsTable?.value.getData();
          if(typeAProductionCheckItems)
            row.XmlData.Items = typeAProductionCheckItems;
        }
        if(row.ContentType == 22) { //生產規格首件QC檢驗紀錄管理
          var winderItemsTable = WinderItemsTable?.value.getData();
          var rollItemsTable = RollItemsTable?.value.getData();
          console.log(winderItemsTable)
          if(winderItemsTable)
            row.XmlData.WinderItems = winderItemsTable;
          if(rollItemsTable)
            row.XmlData.RollItems = rollItemsTable;
        }
        if(row.ContentType == 23) { //生產現場包裝首件QC檢查表
          var productionPackageQCItems = ProductionPackageQCItemsTable?.value.getData();
          if(productionPackageQCItems)
            row.XmlData.Items = productionPackageQCItems;
        }
        if(row.ContentType == 24) { //產品最終品質檢驗報告
          var finalQualityInspectionReportItems = FinalQualityInspectionReportItemsTable?.value.getData();
          if(finalQualityInspectionReportItems)
            row.XmlData.Items = finalQualityInspectionReportItems;
        }
        if(row.ContentType == 25) { //樣品製作樣品通知單
          var trackingItems = TrackingItemsTable?.value.getData();
          if(trackingItems)
            row.XmlData.TrackingItems = trackingItems;
          var staffItems = StaffItemsTable?.value.getData();
          if(staffItems)
            row.XmlData.StaffItems = staffItems;
          var salesItems = SalesItemsTable?.value.getData();
          if(salesItems)
            row.XmlData.SalesItems = salesItems;
        }
        if(row.ContentType == 26) { //紙管濕度檢驗紀錄
          var humidityCheckRecordItems = HumidityCheckRecordItemsTable?.value.getData();
          if(humidityCheckRecordItems)
            row.XmlData.Items = humidityCheckRecordItems;
        }
        if(row.ContentType == 30) { //工單簽核
          var jobTickeDocumentItems = JobTickeDocumentItemsTable?.value.getData();
          if(jobTickeDocumentItems) {
            jobTickeDocumentItems.forEach((e: any) => {
              e.Product = null;
            });
            row.XmlData.Items = jobTickeDocumentItems;  
          }
        }
        if(row.ContentType == 31) { //進款單
          var remittanceSlipItems = RemittanceSlipItemsTable?.value.getData();
          if(remittanceSlipItems) {
            remittanceSlipItems.forEach((e: any) => {
              e.Product = null;
            });
            row.XmlData.Items = remittanceSlipItems;  
          }
        }
        if(row.ContentType == 34) { //年終績效評估
          var performanceEvaluationItemsA = PerformanceEvaluationItemsATable?.value.getData();
          var performanceEvaluationItemsB = PerformanceEvaluationItemsBTable?.value.getData();
          if(performanceEvaluationItemsA) {           
            row.XmlData.ItemsA = performanceEvaluationItemsA;  
          }
          if(performanceEvaluationItemsB) {           
            row.XmlData.ItemsB = performanceEvaluationItemsB;  
          }
        }
        if(row.ContentType == 35) { //差旅與交際費用申請單
          row.XmlData.EmployeeSignature = row.XmlData.EmployeeName;
          var entertainmentItems = EntertainmentItemsTable?.value.getData();
          var travelItems = TravelItemsTable?.value.getData();
          if(entertainmentItems) {           
            row.XmlData.EntertainmentItems = entertainmentItems;  
          }
          if(travelItems) {
            row.XmlData.TravelItems = travelItems;  
          }
        }
        if(row.ContentType == 43) { //製作樣品通知單項目
          var sampleNotificationFormItems = SampleNotificationFormItemsTable?.value.getData();
          if(sampleNotificationFormItems) {
            row.XmlData.Items = sampleNotificationFormItems;  
          }
        }
        if(row.ContentType == 50) { //出差工作日報表
          var businessTripDailyReportItems = BusinessTripDailyReportItemsTable?.value.getData();
          if(businessTripDailyReportItems)
            row.XmlData.Items = businessTripDailyReportItems;
        }
      }
      submit();
    }

    onMounted(() => {
      selectedType.value = 2;
    })

    /** 資訊需求申請使用 */
    const typesSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(Object.values(model!.enums.ITRequirementItem).map(e => { return { Name: e.Name, Id: e.Value } }).find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: Object.values(model!.enums.ITRequirementItem).map(e => { return { Name: e.Name, Id: e.Value } }), totalCount: 1 }));
        },
      },
    };
    const reasonsSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(Object.values(model!.enums.ITRequirementICauseReason).map(e => { return { Name: e.Name, Id: e.Value } }).find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: Object.values(model!.enums.ITRequirementICauseReason).map(e => { return { Name: e.Name, Id: e.Value } }), totalCount: 1 }));
        },
      },
    };
    const annexSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(Object.values(model!.enums.ITRequirementIAnnex).map(e => { return { Name: e.Name, Id: e.Value } }).find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: Object.values(model!.enums.ITRequirementIAnnex).map(e => { return { Name: e.Name, Id: e.Value } }), totalCount: 1 }));
        },
      },
    };

    const wrokOrderItemsSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(Object.values(model!.enums.WorkOrderType).map(e => { return { Name: e.Name, Id: e.Value } }).find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: Object.values(model!.enums.WorkOrderType).map(e => { return { Name: e.Name, Id: e.Value } }), totalCount: 1 }));
        },
      },
    };

    const applicationTypesSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(Object.values(model!.enums.PaymentApplicationType).map(e => { return { Name: e.Name, Id: e.Value } }).find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: Object.values(model!.enums.PaymentApplicationType).map(e => { return { Name: e.Name, Id: e.Value } }), totalCount: 1 }));
        },
      },
    };

    const purchaseRequisitionTypeSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(Object.values(model!.enums.PurchaseRequisitionType).map(e => { return { Name: e.Name, Id: e.Value } }).find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: Object.values(model!.enums.PurchaseRequisitionType).map(e => { return { Name: e.Name, Id: e.Value } }), totalCount: 1 }));
        },
      },
    };

    const jobTickeSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "JobTickeNo",
      valueField: "Id",
      columns: [
        {
          field: "JobTickeNo",
          title: "工單號碼",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("jobTicke/find", value), // eslint-disable-line
        query: params => {
          // params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          return model!.dispatch("jobTicke/query", params) // eslint-disable-line
        }
      }
    };

    const customerSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("customer/find", value), // eslint-disable-line
        query: params => {
          // params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          return model!.dispatch("customer/query", params) // eslint-disable-line
        }
      }
    };

    /** 權限開放申請單使用 */
    const requestContentSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(Object.values(model!.enums.PermissionRequestContent).map(e => { return { Name: e.Name, Id: e.Value } }).find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: Object.values(model!.enums.PermissionRequestContent).map(e => { return { Name: e.Name, Id: e.Value } }), totalCount: 1 }));
        },
      },
    };

    /** 物性檢測申請表使用 */
    const physicalPropertiesTestingSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(Object.values(model!.enums.TestingItem).map(e => { return { Name: e.Name, Id: e.Value } }).find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: Object.values(model!.enums.TestingItem).map(e => { return { Name: e.Name, Id: e.Value } }), totalCount: 1 }));
        },
      },
    };

    /** 來料檢驗使用 */
    const categorySelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("iqcCategory/find", value), // eslint-disable-line
        query: params => {
          params.condition = new Condition("Published", Operator.Equal, true).and(params.condition!);
          params.sortings!.push({ column: 'Ordinal', order: 0 });
          return model!.dispatch("iqcCategory/query", params) // eslint-disable-line
        }
      }
    };

    const fileGrid = ref<any>()
    const fileModal = reactive({
      visible: false,
      selectedRow: {} as any,
      uploadOptions: {
        promises: {
          async query() {
            var condition = new Condition("DocumentId", Operator.Equal, fileModal.selectedRow.Id.toString());
            const sortings = [{ column: "CreatedTime", order: 0 }];
            return await model?.dispatch('files/query', {
              condition: condition,
              sortings: sortings,
            });
          },
          insert(formData: FormData) {
            return model?.dispatch('files/insert', { formData, mode: 'other', entityId: 'Document_' + fileModal.selectedRow.Id, fileType: 2 });
          },
          delete(id: number) {
            return model?.dispatch('files/delete', id);
          }
        }
      },
      show(row: any) {
        fileModal.selectedRow = row;
        if (fileGrid.value) fileGrid.value.refresh();
      }
    })

    const problemContentType = [
      // 人(人員)、機(機械/機台)、料(原物料)、法(製程方法)、環(環境因素)、其他
      { Id: '1', Name: '人(人員)' },
      { Id: '2', Name: '機(機械/機台)' },
      { Id: '3', Name: '料(原物料)' },
      { Id: '4', Name: '法(製程方法)' },
      { Id: '5', Name: '環(環境因素)' },
      { Id: '6', Name: '其他' },
    ]
    const problemContentTypeSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(problemContentType.find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: problemContentType, totalCount: 1 }));
        },
      },
    };

    const remedialType = [
      // 人(人員)、機(機械/機台)、料(原物料)、法(製程方法)、環(環境因素)、其他
      { Id: '1', Name: '人(人員)' },
      { Id: '2', Name: '機(機械/機台)' },
      { Id: '3', Name: '料(原物料)' },
      { Id: '4', Name: '法(製程方法)' },
      { Id: '5', Name: '環(環境因素)' },
      { Id: '6', Name: '其他' },
    ]
    const remedialTypeSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(remedialType.find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: remedialType, totalCount: 1 }));
        },
      },
    };

    const handling = [
      { Id: '1', Name: '要求改善' },
      { Id: '2', Name: '客訴索賠' },
      { Id: '3', Name: '其它' },
    ]
    const handlingSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(handling.find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: handling, totalCount: 1 }));
        },
      },
    };

    const responsibilityUnit = [
      { Id: '1', Name: '分條' },
      { Id: '2', Name: '覆卷' },
      { Id: '3', Name: '裁切' },
      { Id: '4', Name: '包裝' },
      { Id: '5', Name: '倉管' },
      { Id: '6', Name: '品管' },
      { Id: '7', Name: '供應商' },
      { Id: '8', Name: '業務' },
      { Id: '9', Name: '採購' },
      { Id: '10', Name: '生管' },
      { Id: '11', Name: '其它' },
    ]
    const responsibilityUnitSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(responsibilityUnit.find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: responsibilityUnit, totalCount: 1 }));
        },
      },
    };

    const contactDepartment = [
      { Id: '1', Name: 'A.業務部' },
      { Id: '2', Name: 'B.管理部' },
      { Id: '3', Name: 'C.生管組' },
      { Id: '4', Name: 'D.品管組' },
      { Id: '5', Name: 'E.分條組' },
      { Id: '6', Name: 'F.手動分條組' },
      { Id: '7', Name: 'G.切卷組' },
      { Id: '8', Name: 'H.包裝組' },
      { Id: '9', Name: 'I.切管組' },
      { Id: '10', Name: 'J.倉庫組' },
      { Id: '11', Name: 'K.廠務組' },
      { Id: '12', Name: 'L.採購組' },
    ]
    const contactDepartmentSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(contactDepartment.find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: contactDepartment, totalCount: 1 }));
        },
      },
    };

    const messageType = [
      { Id: '1', Name: '一般訊息溝通' },
      { Id: '2', Name: '急件' },
      { Id: '3', Name: '需求' },
      { Id: '4', Name: '通知' },
    ]
    const messageTypeSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(messageType.find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: messageType, totalCount: 1 }));
        },
      },
    };

    return {
      grid,
      form,
      gridOptions,
      formOptions,
      canCreate,
      userSelectorOptions,
      departmentSelectorOptions,
      selectedType,
      model,
      initAddData,
      onGridEdit,
      itemsTable,
      formSubmit,
      readType,
      annexSelectorOptions,
      reasonsSelectorOptions,
      typesSelectorOptions,
      wrokOrderItemsSelectorOptions,
      ProductModifyItemsTable,
      ReRollModifyItemsTable,
      FinishedProductModifyItemsTable,
      MaterialSubstitutesItemsTable,
      applicationTypesSelectorOptions,
      AssessmentItemsTable,
      ProductNumberModifuItemsTable,
      PurchaseRequisitionItemsTable,
      purchaseRequisitionTypeSelectorOptions,
      OrderItemsTable,
      CustomerCreditItemsTable,
      CheckRecordItemsTable,
      IQCItemsTable,
      TypeAProductionCheckItemsTable,
      WinderItemsTable,
      RollItemsTable,
      ProductionPackageQCItemsTable,
      FinalQualityInspectionReportItemsTable,
      SalesItemsTable,
      TrackingItemsTable,
      StaffItemsTable,
      HumidityCheckRecordItemsTable,
      JobTickeDocumentItemsTable,
      RemittanceSlipItemsTable,
      jobTickeSelectorOptions,
      customerSelectorOptions,
      requestContentSelectorOptions,
      PerformanceEvaluationItemsATable,
      PerformanceEvaluationItemsBTable,
      EntertainmentItemsTable,
      TravelItemsTable,
      physicalPropertiesTestingSelectorOptions,
      SampleNotificationFormItemsTable,
      categorySelectorOptions,
      invoice,
      packingList,
      formatDate,
      formatAmount,
      fileModal,
      fileGrid,
      remedialTypeSelectorOptions,
      problemContentTypeSelectorOptions,
      handlingSelectorOptions,
      responsibilityUnitSelectorOptions,
      contactDepartmentSelectorOptions,
      messageTypeSelectorOptions,
    }
  },
  methods: {
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    }
  }
})

<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">建立文件申請</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <button v-show="!grid.isEditing && canCreate" class="button text-white bg-theme-1 shadow-md mr-2 flex items-center" @click="grid.addNewRow">
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增
        </button>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <select
              v-model="selectedType"
              class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border mr-2"
              @change="grid.refresh"
            >
              <option value="">選擇表單類型</option>
              <option
                v-for="(item, index) in Object.values(model.enums.DocumentContentType).map(e => { return { label: e.Name, value: e.Value } })"
                :key="'type' + index" :label="item.label" :value="item.value" class="py-3"></option>
            </select>
          </div>
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input
              type="text"
              class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border"
              v-model.trim="grid.keyword"
              placeholder="請輸入關鍵字"
              @keyup.enter="grid.refresh"
            />
          </div>
          <div class="mt-2 xl:mt-0">
            <button
              type="button"
              class="button w-full sm:w-16 bg-theme-1 text-white"
              @click="grid.refresh"
            >
              搜尋
            </button>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <grid
          ref="grid"
          v-bind="gridOptions"
          @addNewRow="initAddData"
          @edit="onGridEdit"
          @removeSelectedRows="hideBatchDropDown()"
        >
          <template #modal="{ row, submit, reset }">
            <vxe-form
              ref="form"
              v-bind="formOptions"
              :data="row"
              @reset="reset"
              @submit="formSubmit(row, submit)"
            >
              <template #column-file="{ data }">
                <DocumentFileUpload  v-if="data.Id" ref="fileGrid" :title="''" v-bind="fileModal.uploadOptions"
                      :canUpload="!readType"
                      :canDelete="!readType" />
              </template>
              <template #column-submitter="{ data }">
                <select-box v-if="data" v-bind="userSelectorOptions" :readonly="readType" :disabled="readType" v-model="data.SubmitterId" />
              </template>
              <template #column-department="{ data }">
                <select-box v-if="data.XmlData" v-bind="departmentSelectorOptions" :readonly="readType" :disabled="readType" v-model="data.XmlData.DepartmentId" />
              </template>
              <template #column-agent-department="{ data }">
                <select-box v-if="data.XmlData" v-bind="departmentSelectorOptions" :readonly="readType" :disabled="readType" v-model="data.XmlData.AgentDepartmentId" />
              </template>
              <template #column-items="{ data }">
                <InvoiceQuarterlyItemTable v-if="data.XmlData" ref="itemsTable" :aid="data.Id" @refresh="refresh" :data="data.XmlData.InvoiceQuarterlyItems" :readType="readType" />
              </template>
              <template #column-types="{ data }">
                <select-box v-if="data.XmlData" v-bind="typesSelectorOptions" :readonly="readType" :disabled="readType" v-model="data.XmlData.Types" />
              </template>
              <template #column-reasons="{ data }">
                <select-box v-if="data.XmlData" v-bind="reasonsSelectorOptions" :readonly="readType" :disabled="readType" v-model="data.XmlData.CauseReason" />
              </template>
              <template #column-annex="{ data }">
                <select-box v-if="data.XmlData" v-bind="annexSelectorOptions" :readonly="readType" :disabled="readType" v-model="data.XmlData.Annex" />
              </template>
              <!-- BOM表異動申請 1 -->
              <template #column-ProductWrokOrderItems="{ data }">
                <select-box v-if="data.XmlData" v-bind="wrokOrderItemsSelectorOptions" :readonly="readType" :disabled="readType" v-model="data.XmlData.ProductWrokOrderItems" />
              </template>
              <template #column-FinishedProductWrokOrderItems="{ data }">
                <select-box v-if="data.XmlData" v-bind="wrokOrderItemsSelectorOptions" :readonly="readType" :disabled="readType" v-model="data.XmlData.FinishedProductWrokOrderItems" />
              </template>
              <template #column-ReRollWrokOrderItems="{ data }">
                <select-box v-if="data.XmlData" v-bind="wrokOrderItemsSelectorOptions" :readonly="readType" :disabled="readType" v-model="data.XmlData.ReRollWrokOrderItems" />
              </template>
              <template #column-ProductModifyItems="{ data }">
                <BOMUsageQuantityModifyItemTable v-if="data.XmlData" ref="ProductModifyItemsTable" :aid="data.Id" @refresh="refresh" :data="data.XmlData.ProductModifyItems" :readType="readType" />
              </template>
              <template #column-FinishedProductModifyItems="{ data }">
                <BOMUsageQuantityModifyItemTable v-if="data.XmlData" ref="FinishedProductModifyItemsTable" :aid="data.Id" @refresh="refresh" :data="data.XmlData.FinishedProductModifyItems" :readType="readType" />
              </template>
              <template #column-ReRollModifyItems="{ data }">
                <BOMUsageQuantityModifyItemTable v-if="data.XmlData" ref="ReRollModifyItemsTable" :aid="data.Id" @refresh="refresh" :data="data.XmlData.ReRollModifyItems" :readType="readType" />
              </template>
              <!-- BOM表異動申請 1 -->

              <!-- 取替代料件異動申請表 6 -->
              <template #column-MaterialSubstitutesItems="{ data }">
                <MaterialSubstitutesItemsTable v-if="data.XmlData" ref="MaterialSubstitutesItemsTable" :aid="data.Id" @refresh="refresh" :data="data.XmlData.Items" :readType="readType" />
              </template>
              <!-- 取替代料件異動申請表 6 -->

              <!-- 付款申請 9 -->
              <template #column-ApplicationTypes="{ data }">
                <select-box v-if="data.XmlData" v-bind="applicationTypesSelectorOptions" :readonly="readType" :disabled="readType" v-model="data.XmlData.ApplicationTypes" />
              </template>
              <!-- 付款申請 9 -->

              <!-- 人員考核月報表 10 -->
              <template #column-AssessmentItems="{ data }">
                <AssessmentItemsTable v-if="data.XmlData" ref="AssessmentItemsTable" :aid="data.Id" @refresh="refresh" :data="data.XmlData.Items" :readType="readType" />
              </template>
              <!-- 人員考核月報表 9 -->

              <!-- 品號異動申請單 11 -->
              <template #column-ProductNumberModifuItems="{ data }">
                <ProductNumberModifuItemsTable v-if="data.XmlData" ref="ProductNumberModifuItemsTable" :aid="data.Id" @refresh="refresh" :data="data.XmlData.Items" :readType="readType" />
              </template>
              <!-- 品號異動申請單 11 -->

              <!-- 請購單 報價單 12 13 -->
              <template #column-PurchaseRequisitionType="{ data }">
                <select-box v-if="data.XmlData" v-bind="purchaseRequisitionTypeSelectorOptions" :readonly="readType" :disabled="readType" v-model="data.XmlData.Types" />
              </template>
              <template #column-PurchaseRequisitionItems="{ data }">
                <PurchaseRequisitionItemsTable v-if="data.XmlData" ref="PurchaseRequisitionItemsTable" :aid="data.Id" @refresh="refresh" :data="data.XmlData.Items" :readType="readType" />
              </template>
              <!-- 請購單 報價單 12 13 -->

              <!-- 客戶訂單 14 -->
              <template #column-OrderItems="{ data }">
                <OrderItemsTable v-if="data.XmlData" ref="OrderItemsTable" :aid="data.Id" @refresh="refresh" :data="data.XmlData.Items" :readType="readType" />
              </template>
              <!-- 客戶訂單 14 -->

              <!-- 客戶超限放行單 15 -->
              <template #column-CustomerCreditItems="{ data }">
                <CustomerCreditItemsTable v-if="data.XmlData" ref="CustomerCreditItemsTable" :aid="data.Id" @refresh="refresh" :data="data.XmlData.Items" :readType="readType" />
              </template>
              <!-- 客戶超限放行單 15 -->

              <!-- 巡檢作業 16 -->
              <template #column-CheckRecordItems="{ data }">
                <CheckRecordItemsTable v-if="data.XmlData" ref="CheckRecordItemsTable" :aid="data.Id" @refresh="refresh" :data="data.XmlData.Items" :readType="readType" />
              </template>
              <!-- 巡檢作業 16 -->

              <!-- 來料檢驗 17 -->
              <template #column-IQCItems="{ data }">
                <IQCItemsTable v-if="data.XmlData" ref="IQCItemsTable" :contentType="data.ContentType" :aid="data.Id" @refresh="refresh" :data="data.XmlData.Items" :readType="readType" />
              </template>
              <!-- 來料檢驗 17 -->

              <!-- 生產規格首件QC檢驗紀錄表(分條)  21 -->
              <template #column-TypeAProductionCheckItems="{ data }">
                <TypeAProductionCheckItemsTable v-if="data.XmlData" ref="TypeAProductionCheckItemsTable" :aid="data.Id" @refresh="refresh" :data="data.XmlData.Items" :readType="readType" />
              </template>
              <!-- 生產規格首件QC檢驗紀錄表(分條)  21 -->

              <!-- 生產規格首件QC檢驗紀錄表(分條)  22 45 -->
              <template #column-WinderItems="{ data }">
                <WinderItemsTable v-if="data.XmlData" ref="WinderItemsTable" :aid="data.Id" @refresh="refresh" :data="data.XmlData.WinderItems" :readType="readType" />
              </template>
              <template #column-RollItems="{ data }">
                <RollItemsTable v-if="data.XmlData" ref="RollItemsTable" :aid="data.Id" @refresh="refresh" :data="data.XmlData.RollItems" :readType="readType" />
              </template>
              <!-- 生產規格首件QC檢驗紀錄表(分條)  22 45 -->

              <!-- 生產現場包裝首件QC檢查表 23 -->
              <template #column-ProductionPackageQCItems="{ data }">
                <ProductionPackageQCItemsTable v-if="data.XmlData" ref="ProductionPackageQCItemsTable" :contentType="data.ContentType" :aid="data.Id" @refresh="refresh" :data="data.XmlData.Items" :readType="readType" />
              </template>
              <!-- 生產現場包裝首件QC檢查表 23 -->

              <!-- 產品最終品質檢驗報告 24 -->
              <template #column-FinalQualityInspectionReportItems="{ data }">
                <FinalQualityInspectionReportItemsTable v-if="data.XmlData" ref="FinalQualityInspectionReportItemsTable" :contentType="data.ContentType" :aid="data.Id" @refresh="refresh" :data="data.XmlData.Items" :readType="readType" />
              </template>
              <!-- 產品最終品質檢驗報告 24 -->

              <!-- 樣品製作樣品通知單 25 -->
              <template #column-SalesItems="{ data }">
                <SalesItemsTable v-if="data.XmlData" ref="SalesItemsTable" :contentType="data.ContentType" :aid="data.Id" @refresh="refresh" :data="data.XmlData.SalesItems" :readType="readType" />
              </template>
              <template #column-StaffItems="{ data }">
                <StaffItemsTable v-if="data.XmlData" ref="StaffItemsTable" :contentType="data.ContentType" :aid="data.Id" @refresh="refresh" :data="data.XmlData.StaffItems" :readType="readType" />
              </template>
              <template #column-TrackingItems="{ data }">
                <TrackingItemsTable v-if="data.XmlData" ref="TrackingItemsTable" :contentType="data.ContentType" :aid="data.Id" @refresh="refresh" :data="data.XmlData.TrackingItems" :readType="readType" />
              </template>
              <!-- 樣品製作樣品通知單 25 -->

              <!-- 產品最終品質檢驗報告 26 -->
              <template #column-HumidityCheckRecordItems="{ data }">
                <HumidityCheckRecordItemsTable v-if="data.XmlData" ref="HumidityCheckRecordItemsTable" :contentType="data.ContentType" :aid="data.Id" @refresh="refresh" :data="data.XmlData.Items" :readType="readType" />
              </template>
              <!-- 產品最終品質檢驗報告 26 -->

              <!-- 工單 30 -->
              <template #column-JobTickeDocumentItems="{ data }">
                <JobTickeDocumentItemsTable v-if="data.XmlData" ref="JobTickeDocumentItemsTable" :contentType="data.ContentType" :aid="data.Id" @refresh="refresh" :data="data.XmlData.Items" :readType="readType" />
              </template>
              <!-- 工單 30 -->

              <!-- 工單 31 -->
              <template #column-JobTicke="{ data }">
                <select-box v-if="data.XmlData" v-bind="jobTickeSelectorOptions" :readonly="readType" :disabled="readType" v-model="data.XmlData.JobTickeId" />
              </template>
              <template #column-Customer="{ data }">
                <select-box v-if="data.XmlData" v-bind="customerSelectorOptions" :readonly="readType" :disabled="readType" v-model="data.XmlData.CustomerId" />
              </template>
              <template #column-RemittanceSlipItems="{ data }">
                <RemittanceSlipItemsTable v-if="data.XmlData" ref="RemittanceSlipItemsTable" :contentType="data.ContentType" :aid="data.Id" @refresh="refresh" :data="data.XmlData.Items" :readType="readType" />
              </template>
              <!-- 工單 31 -->

              <!-- 權限開放申請單 32 33 -->
              <template #column-requestContent="{ data }">
                <select-box v-if="data.XmlData" v-bind="requestContentSelectorOptions" :readonly="readType" :disabled="readType" v-model="data.XmlData.RequestContent" />
              </template>
              <!-- 權限開放申請單 32 33 -->

              <!-- 年終績效評估 34 -->
              <template #column-PerformanceEvaluationItemsA="{ data }">
                <PerformanceEvaluationItemsATable v-if="data.XmlData" ref="PerformanceEvaluationItemsATable" :contentType="data.ContentType" :aid="data.Id" @refresh="refresh" :data="data.XmlData.ItemsA" :readType="readType" />
              </template>
              <template #column-PerformanceEvaluationItemsB="{ data }">
                <PerformanceEvaluationItemsBTable v-if="data.XmlData" ref="PerformanceEvaluationItemsBTable" :contentType="data.ContentType" :aid="data.Id" @refresh="refresh" :data="data.XmlData.ItemsB" :readType="readType" />
              </template>
              <template #column-PerformanceEvaluationPartA>
                <div>
                  <table class="w-full table-auto w-auto border-collapse border border-gray-400">
                    <thead>
                      <tr>
                        <th class="border border-gray-400 bg-gray-200 py-2 px-4 text-center" colspan="5">核心職能評核(請參考以下評分標準)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="border border-gray-400 py-2 px-4">C. 需輔導</td>
                        <td class="border border-gray-400 py-2 px-4">I. 需改進</td>
                        <td class="border border-gray-400 py-2 px-4">G. 良好</td>
                        <td class="border border-gray-400 py-2 px-4">D. 優秀</td>
                        <td class="border border-gray-400 py-2 px-4">E. 傑出</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </template>
              <template #column-Quality>
                <!-- 持續投注於注意光義包裝工業公司成功之正向、具體效度之工作面向。 -->
                <div class="w-full ml-3">品質 Quality Conscious</div>
              </template>
              <template #column-Interity>
                <div class="w-full ml-3">誠實正直 Integrity</div>
              </template>
              <template #column-Professionalism>
                <div class="w-full ml-3">專業 Professionalism</div>
              </template>
              <template #column-Progress>
                <div class="w-full ml-3">持續進步 Contribution to Continuous Improvement</div>
              </template>
              <template #column-Problem>
                <div class="w-full ml-3">解決問題 Problem Solving</div>
              </template>
              <template #column-Teamwork>
                <div class="w-full ml-3">團隊精神 Teamwork</div>
              </template>
              <template #column-Spontaneity>
                <div class="w-full ml-3">自動自發 Spontaneity</div>
              </template>
              <template #column-Overall>
                <div class="w-full ml-3">整體自評 Overall Rating</div>
              </template>
              <template #column-Next>
                <div class="w-full ml-3">下次評核之個人工作目標設定</div>
              </template>
              <template #column-Signature>
                <div class="w-full ml-3">簽名</div>
              </template>
              <!-- 年終績效評估 34 -->

              <!-- 差旅與交際費用申請單 35 -->
              <template #column-TravelItems="{ data }">
                <TravelItemsTable v-if="data.XmlData" ref="TravelItemsTable" :contentType="data.ContentType" :aid="data.Id" @refresh="refresh" :data="data.XmlData.TravelItems" :readType="readType" />
              </template>
              <template #column-EntertainmentItems="{ data }">
                <EntertainmentItemsTable v-if="data.XmlData" ref="EntertainmentItemsTable" :contentType="data.ContentType" :aid="data.Id" @refresh="refresh" :data="data.XmlData.EntertainmentItems" :readType="readType" />
              </template>
              <!-- 差旅與交際費用申請單 35 -->

              <!-- 物性檢測申請表 40 -->
              <template #column-TestingItem="{ data }">
                <select-box v-if="data.XmlData" v-bind="physicalPropertiesTestingSelectorOptions" :readonly="readType" :disabled="readType" v-model="data.XmlData.Items" />
              </template>
              <!-- 物性檢測申請表 40 -->

              <!-- 製作樣品通知單項目 43 -->
              <template #column-SampleNotificationFormItems="{ data }">
                <SampleNotificationFormItemsTable v-if="data.XmlData" ref="SampleNotificationFormItemsTable" :contentType="data.ContentType" :aid="data.Id" @refresh="refresh" :data="data.XmlData.Items" :readType="readType" />
              </template>
              <!-- 製作樣品通知單項目 43 -->

              <!-- 來料檢驗 46 -->
              <template #column-CategoryItem="{ data }">
                <CategoryItemTable v-if="data.XmlData" ref="CategoryItemTable" :aid="data.Id" @refresh="refresh" :data="data.XmlData.CategoryItems" :readType="readType" />
              </template>
              <template #column-Items="{ data }">
                <IQCTableItemsTable v-if="data.XmlData" ref="IQCTableItemsTable" :aid="data.Id" @refresh="refresh" :data="data.XmlData.Items" :readType="readType" />
              </template>
              <template #column-category="{ data }">
                <select-box v-if="data.XmlData" v-bind="categorySelectorOptions" :readonly="readType" :disabled="readType" v-model="data.XmlData.CategoryId" @change="changeCategory(data)" />
              </template>
              <!-- 來料檢驗 46 -->

              <!-- 品質異常處理單 47 -->
              <template #column-ErrorSteps="{ data }">
                <select-box v-if="data.XmlData" v-bind="errorStepsSelectorOptions" :readonly="readType" :disabled="readType" v-model="data.XmlData.ErrorSteps" />
              </template>
              <template #column-DiscoveryUnits="{ data }">
                <select-box v-if="data.XmlData" v-bind="discoveryUnitsSelectorOptions" :readonly="readType" :disabled="readType" v-model="data.XmlData.DiscoveryUnits" />
              </template>
              <template #column-Handling="{ data }">
                <select-box v-if="data.XmlData" v-bind="handlingSelectorOptions" :readonly="readType" :disabled="readType" v-model="data.XmlData.Handling" />
              </template>
              <template #column-ResponsibilityUnit="{ data }">
                <select-box v-if="data.XmlData" v-bind="responsibilityUnitSelectorOptions" :readonly="readType" :disabled="readType" v-model="data.XmlData.ResponsibilityUnit" />
              </template>
              <template #column-RemedialType="{ data }">
                <select-box v-if="data.XmlData" v-bind="remedialTypeSelectorOptions" :readonly="readType" :disabled="readType" v-model="data.XmlData.RemedialType" />
              </template>
              <!-- 品質異常處理單 47 -->

              <template #column-hr>
                <div class="border-t border-gray-300"></div>
              </template>
              <!-- 品質異常原因調查表 48 -->
              <template #column-ProblemContentType="{ data }">
                <select-box v-if="data.XmlData" v-bind="problemContentTypeSelectorOptions" :readonly="readType" :disabled="readType" v-model="data.XmlData.ProblemContentType" />
              </template>
              <!-- 品質異常原因調查表 48 -->

              <!-- 品質異常原因調查表 49 -->
              <template #column-ContactDepartment="{ data }">
                <select-box v-if="data.XmlData" v-bind="contactDepartmentSelectorOptions" :readonly="readType" :disabled="readType" v-model="data.XmlData.ContactDepartment" />
              </template>
              <template #column-MessageType="{ data }">
                <select-box v-if="data.XmlData" v-bind="messageTypeSelectorOptions" :readonly="readType" :disabled="readType" v-model="data.XmlData.MessageType" />
              </template>
              <!-- 品質異常原因調查表 49 -->

              <!-- 出差工作日報表 50 -->
              <template #column-BusinessTripDailyReportItems="{ data }">
                <BusinessTripDailyReportItemsTable v-if="data.XmlData" ref="BusinessTripDailyReportItemsTable" :aid="data.Id" @refresh="refresh" :data="data.XmlData.Items" :readType="readType" />
              </template>
              <!-- 出差工作日報表 50 -->
            </vxe-form>
          </template>
          <template #modal-footer="{ row }">
            <vxe-button v-if="row.Status !== 0" type="button" status="primary" content="關閉" @click="() => { grid.isModalPopup = false; grid.editingRow = null; }"></vxe-button>
            <vxe-button v-else type="submit" status="primary" content="確認" @click="$refs.form.validate(errMap => { if (errMap === undefined) $refs.form.dispatchEvent('submit'); })"></vxe-button>
            <!-- <vxe-button type="reset" content="重置" @click="$refs.form.dispatchEvent('reset')"></vxe-button> -->
          </template>
          <template #filter-name="{ column, $panel }">
            <div
              v-for="(option, index) in column.filters"
              :key="index"
              class="flex item-center justify-center"
            >
              <input
                type="checkbox"
                class="input input--switch border mt-4 ml-2"
                :checked="option.checked"
                @input="grid.changeFilterEvent($event, column, option, $panel, true)"
              />
              <div class="relative m-2">
                <div
                  class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600"
                >
                  {{ option.label }}
                </div>
                <input
                  v-model.trim="option.data"
                  :disabled="!option.checked"
                  type="text"
                  class="input pl-12 w-full border col-span-4"
                  placeholder="請輸入文字"
                  @input="grid.changeFilterEvent($event, column, option, $panel)"
                />
              </div>
            </div>
          </template>
          <template #operate="{ row }">
            <button v-if="row.Status !== 0"
              class="button rounded-full mr-1 bg-blue-600 text-white" title="查看" @click.stop="grid.edit(row)">
              <FontAwesome icon="book-open" class="w-4 h-4" />
            </button>
            <div v-else class="flex justify-center">
              <button class="button rounded-full mr-1 bg-blue-600 text-white" title="編輯" @click.stop="grid.edit(row)">
                <FontAwesome icon="highlighter" class="w-4 h-4" />
              </button>
              <button type="button" class="button rounded-full bg-red-600 text-white" title="刪除"
                @click.stop="grid.remove(row)">
                <FontAwesome icon="trash" class="w-4 h-4" />
              </button>
            </div>
          </template>
        </grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
    <vxe-modal resize v-model="flowModal.visible" width="70%" title="文件流程" height="600" show-zoom="true">
      <InProgressFlowTable ref="flowGrid" v-bind="flowModal.gridOptions" :modelValue="flowModal.visible" :contentType="flowModal.selectedRow.ContentType" :read="flowModal.selectedRow.Status != 0"  :sid="flowModal.selectedRow.Id" />
    </vxe-modal>
    <!-- <vxe-modal v-model="flowModal.visible" width="60%" height="500" title="新增建立文件申請" show-zoom="true">
      <FlowTable ref="flowGrid" :visible="flowModal.visible" @submit="flowModal.save" />
    </vxe-modal>
    <vxe-modal v-model="deleteModal.visible" width="30%" height="350" title="刪除建立文件申請" show-zoom="true">
      <DeleteTable ref="deleteGrid" :visible="deleteModal.visible" @save="deleteModal.save" />
    </vxe-modal> -->
  </div>
</template>

<script lang="ts">
/* eslint-disable */
import CloudFun, { computed, defineComponent, ref, Condition, Operator, reactive, onMounted } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import CheckBoxList from '@/cloudfun/components/CheckBoxList.vue'
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import { getformOption, getType } from '@/libs/formItems';
import InvoiceQuarterlyItemTable from "@/components/document/InvoiceQuarterlyItemTable.vue";
import BOMUsageQuantityModifyItemTable from "@/components/document/BOMUsageQuantityModifyItemTable.vue";
import MaterialSubstitutesItemsTable from "@/components/document/MaterialSubstitutesItemsTable.vue";
import AssessmentItemsTable from "@/components/document/AssessmentItemsTable.vue";
import ProductNumberModifuItemsTable from "@/components/document/ProductNumberModifuItemsTable.vue";
import PurchaseRequisitionItemsTable from "@/components/document/PurchaseRequisitionItemsTable.vue";
import OrderItemsTable from "@/components/document/OrderItemsTable.vue";
import CustomerCreditItemsTable from "@/components/document/CustomerCreditItemsTable.vue";
import CheckRecordItemsTable from "@/components/document/CheckRecordItemsTable.vue";
import IQCItemsTable from "@/components/document/IQCItemsTable.vue";
import TypeAProductionCheckItemsTable from "@/components/document/TypeAProductionCheckItemsTable.vue";
import WinderItemsTable from "@/components/document/WinderItemsTable.vue";
import RollItemsTable from "@/components/document/RollItemsTable.vue";
import ProductionPackageQCItemsTable from "@/components/document/ProductionPackageQCItemsTable.vue";
import SalesItemsTable from "@/components/document/SalesItemsTable.vue";
import StaffItemsTable from "@/components/document/StaffItemsTable.vue";
import TrackingItemsTable from "@/components/document/TrackingItemsTable.vue";
import FinalQualityInspectionReportItemsTable from "@/components/document/FinalQualityInspectionReportItemsTable.vue";
import HumidityCheckRecordItemsTable from "@/components/document/HumidityCheckRecordItemsTable.vue";
import JobTickeDocumentItemsTable from "@/components/document/JobTickeDocumentItemsTable.vue";
import RemittanceSlipItemsTable from "@/components/document/RemittanceSlipItemsTable.vue";
import PerformanceEvaluationItemsATable from "@/components/document/PerformanceEvaluationItemsATable.vue";
import PerformanceEvaluationItemsBTable from "@/components/document/PerformanceEvaluationItemsBTable.vue";
import EntertainmentItemsTable from "@/components/document/EntertainmentItemsTable.vue";
import TravelItemsTable from "@/components/document/TravelItemsTable.vue";
import SampleNotificationFormItemsTable from "@/components/document/SampleNotificationFormItemsTable.vue";
import CategoryItemTable from "@/components/document/CategoryItemTable.vue";
import IQCTableItemsTable from "@/components/document/IQCTableItemsTable.vue";
import { formatAmount, formatResultTotalAmount } from '@/libs/utils'
import formatDate from 'xe-utils/toDateString';
import DocumentFileUpload from "@/components/file/DocumentFileUpload.vue";
import BusinessTripDailyReportItemsTable from "@/components/document/BusinessTripDailyReportItemsTable.vue";

export default defineComponent({
  components: {
    Grid,
    SelectBox,
    SalesItemsTable,
    StaffItemsTable,
    TrackingItemsTable,
    InvoiceQuarterlyItemTable,
    BOMUsageQuantityModifyItemTable,
    MaterialSubstitutesItemsTable,
    AssessmentItemsTable,
    ProductNumberModifuItemsTable,
    PurchaseRequisitionItemsTable,
    OrderItemsTable,
    CustomerCreditItemsTable,
    CheckRecordItemsTable,
    IQCItemsTable,
    TypeAProductionCheckItemsTable,
    WinderItemsTable,
    RollItemsTable,
    ProductionPackageQCItemsTable,
    FinalQualityInspectionReportItemsTable,
    HumidityCheckRecordItemsTable,
    JobTickeDocumentItemsTable,
    RemittanceSlipItemsTable,
    PerformanceEvaluationItemsATable,
    PerformanceEvaluationItemsBTable,
    EntertainmentItemsTable,
    TravelItemsTable,
    SampleNotificationFormItemsTable,
    CategoryItemTable,
    IQCTableItemsTable,
    DocumentFileUpload,
    BusinessTripDailyReportItemsTable,
  },
  setup () {
    const model = CloudFun.current?.model
    const grid = ref<any>({})
    var printColumns = [{ field: 'Name' }, { field: 'Description' }]
    const selectedType = ref<any>('');

    const user = CloudFun.current?.user;
    const policy = CloudFun.current?.policy;

    const checkPermissions = (key: string) => {
      // console.log(user?.Permissions)
      try {
        return user && policy?.isApproved(user, key);
      } catch {
        return false;
      }
    };

    const canCreate = checkPermissions("DocumentCreate");
    const canUpdate = checkPermissions("DocumentUpdate");
    const canDelete = checkPermissions("DocumentDelete");

    const gridOptions: GridOptions = {
      id: 'document',
      title: '建立文件申請',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      canCreate: canCreate,
      canUpdate: canUpdate,
      canDelete: canDelete,
      multiselect: false,
      printConfig: {
        sheetName: '清單',
        columns: printColumns,
        modes: ['current', 'selected', 'all']
      },
      exportConfig: {
        filename: '清單',
        type: 'csv',
        types: ['html', 'csv'],
        mode: 'all',
        modes: ['current', 'selected', 'all'],
        columns: printColumns
      },
      columns: [
        { field: 'Title', title: '名稱', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: "Type", title: "文件類型", showHeaderOverflow: true, showOverflow: true, formatter: ({ cellValue }) => model ? Object.values(model.enums.DocumentType).find(e => e.Value === cellValue)?.Name : undefined },
        { field: "ContentType", title: "表單類型", showHeaderOverflow: true, showOverflow: true, formatter: ({ cellValue }) => model ? Object.values(model.enums.DocumentContentType).find(e => e.Value === cellValue)?.Name : undefined },
        { field: 'Submitter.Person.Name', title: '申請人', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'CurrentSignatureLevel', title: '目前簽核層級', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'Status', title: '簽核狀態', showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => model ? Object.values(model.enums.DocumentStatus).find(e => e.Value === cellValue)?.Name : undefined },
        // {
        //   title: '文件流程',
        //   width: '100',
        //   slots: { default: "flow" }, align: 'center'
        // },
      ],
      promises: {
        query: model ? async (params) => {
          if (selectedType.value !== "") {
            params.condition!.and("ContentType", Operator.Equal, selectedType.value);
          }
          if (params?.sortings?.length === 0) {
            params.sortings = [{ column: 'CreatedTime', order: 1 }];
          }
          return await model.dispatch('document/query', params);
        } : undefined,
        queryAll: model ? () => model.dispatch('document/query') : undefined,
        save: model
          ? (params) => model.dispatch('document/save', params)
          : undefined
      },
      modalConfig: { width: "60%", showFooter: true, fullscreen: true }
    }

    // const contentTypeChange = (item: any) => {
    //   if (item.data.ContentType >= 0) {
    //     var contentTypeOption = getformOption(item.data.ContentType) as any;
    //     formOptions.items?.push({
    //       field: 'ApplicationDate',
    //       title: '填表(申請)日期',
    //       span: 12,
    //       itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期' } }
    //     })
    //     console.log(form.value)
    //     //Array.prototype.push.apply(formOptions.items, contentTypeOption.items);
    //     //Array.prototype.push.apply(formOptions.rules, contentTypeOption.items);
        
    //   }
    // }

    const form = ref<any>();
    const readType = ref(false);
    const defaultFormOptions: VxeFormProps = {
      items: [
        {
          field: 'Title',
          title: '名稱',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字' },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'SubmittingTime',
          title: '提交時間',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期' } }
        },
        {
          field: 'Type',
          title: '文件類型',
          span: 12,
          itemRender: { name: "$select", props: {}, options: model ? Object.values(model.enums.DocumentType).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        },
        {
          field: 'ContentType',
          title: '表單類型',
          span: 12,
          itemRender: { name: "$select", props: {}, options: model ? Object.values(model.enums.DocumentContentType).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        },
        {
          field: 'SubmitterId',
          title: '申請人',
          span: 12,
          slots: { default: "column-submitter" }
        },
        {
          field: 'CurrentSignatureLevel',
          title: '目前簽核層級',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '', type: 'number', disabled: true },
          }
        },
        {
          field: 'Status',
          title: '簽核狀態',
          span: 12,
          itemRender: { name: "$select", props: { disabled: true }, options: model ? Object.values(model.enums.DocumentStatus).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        },
      ],
      rules: {
        Title: [{ type: 'string', required: true }],
        SubmittingTime: [{ required: true }],
        Type: [{ required: true }],
        ContentType: [{ required: true }],
      }
    }

    const formOptions: VxeFormProps = {
      titleWidth: 100,
      titleAlign: 'right',      
    }

    const userSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Person.Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("employee/find", value), // eslint-disable-line
        query: params => {
          // params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          return model!.dispatch("employee/query", params) // eslint-disable-line
        }
      }
    };

    const departmentSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("department/find", value), // eslint-disable-line
        query: params => {
          // params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          return model!.dispatch("department/query", params) // eslint-disable-line
        }
      }
    };

    const initAddData = (row: any, callback: any) => {
      row.SubmitterId = user?.Id;
      row.SubmittingTime = new Date();

      row.Status = 0;
      readType.value = false;
      formOptions.items = defaultFormOptions.items;
      formOptions.rules = defaultFormOptions.rules;

      grid.value.isModalPopup = true;
      callback();
    }

    const setDefaultReadOnly = (data: any) => {
      data.items[0].itemRender.props.readonly = true;
      data.items[1].itemRender.props.readonly = true;
      data.items[2].itemRender.props.disabled = true;
      data.items[3].itemRender.props.disabled = true;
      return data;
    }

    const invoice = ref<any>();
    const packingList = ref<any>();
    const onGridEdit = async (row: any, callback: any) => {
      const entity = row.Id ? await model?.dispatch('document/find', row.Id) : undefined
      Object.assign(row, entity);
      if (row.Id) {
        readType.value = false;
        var contentTypeOption = getformOption(row.ContentType, model, row.Status == 0 ? false : true) as any;
        // console.log(contentTypeOption)
        let defaultCopy = JSON.parse(JSON.stringify(defaultFormOptions));
        if(row.Status != 0) { //唯讀
          readType.value = true;
          defaultCopy = setDefaultReadOnly(defaultCopy);
        }
        Array.prototype.push.apply(defaultCopy.items, contentTypeOption.items);
        for (const key in contentTypeOption.rules) {
          // console.log(key, contentTypeOption.rules[key])
          defaultCopy.rules[key] = contentTypeOption.rules[key];
        }
        formOptions.items = defaultCopy.items;
        formOptions.rules = defaultCopy.rules;

        if(row.ContentType == 26) {
          var year = new Date().getFullYear();
          var month = new Date().getMonth() + 1;
          if(!row.XmlData.Year)
            row.XmlData.Year = year;
          if(!row.XmlData.Month)
            row.XmlData.Month = month;
        }
        if(row.ContentType == 31) {
          invoice.value = row.XmlData.InvoiceId ? await model?.dispatch('invoice/find', row.XmlData.InvoiceId) : null;
          const condition = new Condition("InvoiceNumber", Operator.Equal, row.XmlData.InvoiceNumber);
          var list = row.XmlData.InvoiceNumber ? await model?.dispatch("packingList/query", { condition }): null;
          packingList.value = list?.[0];
        }
        if(row.ContentType == 47 || row.ContentType == 49) {
          if(fileModal) fileModal.show(row);
        }
        
        // console.log(row)
        // for (const key in row.XmlData) {
        //   formOptions.items?.push({
        //     field: key,
        //     title: key,
        //     span: 12,
        //     itemRender: {
        //       name: '$input',
        //       props: { placeholder: '請輸入文字' },
        //     }
        //   })
        // }
      }
      callback()
    }

    const itemsTable = ref<any>();
    const ProductModifyItemsTable = ref<any>();
    const FinishedProductModifyItemsTable = ref<any>();
    const ReRollModifyItemsTable = ref<any>();
    const MaterialSubstitutesItemsTable = ref<any>();
    const AssessmentItemsTable = ref<any>();
    const ProductNumberModifuItemsTable = ref<any>();
    const PurchaseRequisitionItemsTable = ref<any>();
    const OrderItemsTable = ref<any>();
    const CustomerCreditItemsTable = ref<any>();
    const CheckRecordItemsTable = ref<any>();
    const IQCItemsTable = ref<any>();
    const TypeAProductionCheckItemsTable = ref<any>();
    const WinderItemsTable = ref<any>();
    const RollItemsTable = ref<any>();
    const ProductionPackageQCItemsTable = ref<any>();
    const FinalQualityInspectionReportItemsTable = ref<any>();
    const SalesItemsTable = ref<any>();
    const StaffItemsTable = ref<any>();
    const TrackingItemsTable = ref<any>();
    const HumidityCheckRecordItemsTable = ref<any>();
    const JobTickeDocumentItemsTable = ref<any>();
    const RemittanceSlipItemsTable = ref<any>();
    const PerformanceEvaluationItemsATable = ref<any>();
    const PerformanceEvaluationItemsBTable = ref<any>();
    const EntertainmentItemsTable = ref<any>();
    const TravelItemsTable = ref<any>();
    const SampleNotificationFormItemsTable = ref<any>();
    const BusinessTripDailyReportItemsTable = ref<any>();
    
    const formSubmit = (row: any, submit: any) => {
      if(row.Id) {
        if(row.ContentType == 2 || row.ContentType == 4) { //國內外請款單
          var invoiceQuarterlyItems = itemsTable?.value.getData();
          // console.log(invoiceQuarterlyItems)
          if(invoiceQuarterlyItems)
            row.XmlData.InvoiceQuarterlyItems = invoiceQuarterlyItems;
        }
        if(row.ContentType == 1) { //BOM表異動申請
          //產品
          var ProductModifyItems = ProductModifyItemsTable?.value.getData();
          if(ProductModifyItems)
            row.XmlData.ProductModifyItems = ProductModifyItems;
          //成品
          var FinishedProductModifyItems = FinishedProductModifyItemsTable?.value.getData();
          if(FinishedProductModifyItems)
            row.XmlData.FinishedProductModifyItems = FinishedProductModifyItems;
          //覆卷
          var ReRollModifyItems = ReRollModifyItemsTable?.value.getData();
          if(ReRollModifyItems)
            row.XmlData.ReRollModifyItems = ReRollModifyItems;         
        }
        if(row.ContentType == 6) { //取替代料件異動申請表
          var MaterialSubstitutesItems = MaterialSubstitutesItemsTable?.value.getData();
          // console.log(invoiceQuarterlyItems)
          if(MaterialSubstitutesItems)
            row.XmlData.Items = MaterialSubstitutesItems;
        }
        if(row.ContentType == 10) { //人員考核月報表
          var invoiceQuarterlyItems = itemsTable?.value.getData();
          if(invoiceQuarterlyItems)
            row.XmlData.InvoiceQuarterlyItems = invoiceQuarterlyItems;
          var assessmentItems = AssessmentItemsTable?.value.getData();
          if(assessmentItems)
            row.XmlData.Items = assessmentItems;
        }
        if(row.ContentType == 11) { //品號異動申請單
          var productNumberModifuItems = ProductNumberModifuItemsTable?.value.getData();
          if(productNumberModifuItems)
            row.XmlData.Items = productNumberModifuItems;
        }
        if(row.ContentType == 12 || row.ContentType == 13) { //請購單 報價單
          var purchaseRequisitionItems = PurchaseRequisitionItemsTable?.value.getData();
          if(purchaseRequisitionItems)
            row.XmlData.Items = purchaseRequisitionItems;
        }
        if(row.ContentType == 14) { //客戶訂單
          var orderItems = OrderItemsTable?.value.getData();
          if(orderItems)
            row.XmlData.Items = orderItems;
        }
        if(row.ContentType == 15) { //客戶超限放行單
          var customerCreditItems = CustomerCreditItemsTable?.value.getData();
          if(customerCreditItems)
            row.XmlData.Items = customerCreditItems;
        }
        if(row.ContentType == 15) { //客戶超限放行單
          var customerCreditItems = CustomerCreditItemsTable?.value.getData();
          if(customerCreditItems)
            row.XmlData.Items = customerCreditItems;
        }
        if(row.ContentType == 16) { //巡檢表
          var checkRecordItems = CheckRecordItemsTable?.value.getData();
          if(checkRecordItems)
            row.XmlData.Items = checkRecordItems;
        }
        if(row.ContentType == 17 || row.ContentType == 18 || row.ContentType == 19 || row.ContentType == 20) { //來料檢驗
          var iQCItems = IQCItemsTable?.value.getData();
          if(iQCItems)
            row.XmlData.Items = iQCItems;
        }
        if(row.ContentType == 21) { //生產規格首件QC檢驗紀錄管理
          var typeAProductionCheckItems = TypeAProductionCheckItemsTable?.value.getData();
          if(typeAProductionCheckItems)
            row.XmlData.Items = typeAProductionCheckItems;
        }
        if(row.ContentType == 22) { //生產規格首件QC檢驗紀錄管理
          var winderItemsTable = WinderItemsTable?.value.getData();
          var rollItemsTable = RollItemsTable?.value.getData();
          console.log(winderItemsTable)
          if(winderItemsTable)
            row.XmlData.WinderItems = winderItemsTable;
          if(rollItemsTable)
            row.XmlData.RollItems = rollItemsTable;
        }
        if(row.ContentType == 23) { //生產現場包裝首件QC檢查表
          var productionPackageQCItems = ProductionPackageQCItemsTable?.value.getData();
          if(productionPackageQCItems)
            row.XmlData.Items = productionPackageQCItems;
        }
        if(row.ContentType == 24) { //產品最終品質檢驗報告
          var finalQualityInspectionReportItems = FinalQualityInspectionReportItemsTable?.value.getData();
          if(finalQualityInspectionReportItems)
            row.XmlData.Items = finalQualityInspectionReportItems;
        }
        if(row.ContentType == 25) { //樣品製作樣品通知單
          var trackingItems = TrackingItemsTable?.value.getData();
          if(trackingItems)
            row.XmlData.TrackingItems = trackingItems;
          var staffItems = StaffItemsTable?.value.getData();
          if(staffItems)
            row.XmlData.StaffItems = staffItems;
          var salesItems = SalesItemsTable?.value.getData();
          if(salesItems)
            row.XmlData.SalesItems = salesItems;
        }
        if(row.ContentType == 26) { //紙管濕度檢驗紀錄
          var humidityCheckRecordItems = HumidityCheckRecordItemsTable?.value.getData();
          if(humidityCheckRecordItems)
            row.XmlData.Items = humidityCheckRecordItems;
        }
        if(row.ContentType == 30) { //工單簽核
          var jobTickeDocumentItems = JobTickeDocumentItemsTable?.value.getData();
          if(jobTickeDocumentItems) {
            jobTickeDocumentItems.forEach((e: any) => {
              e.Product = null;
            });
            row.XmlData.Items = jobTickeDocumentItems;  
          }
        }
        if(row.ContentType == 31) { //進款單
          var remittanceSlipItems = RemittanceSlipItemsTable?.value.getData();
          if(remittanceSlipItems) {
            remittanceSlipItems.forEach((e: any) => {
              e.Product = null;
            });
            row.XmlData.Items = remittanceSlipItems;  
          }
        }
        if(row.ContentType == 34) { //年終績效評估
          var performanceEvaluationItemsA = PerformanceEvaluationItemsATable?.value.getData();
          var performanceEvaluationItemsB = PerformanceEvaluationItemsBTable?.value.getData();
          if(performanceEvaluationItemsA) {           
            row.XmlData.ItemsA = performanceEvaluationItemsA;  
          }
          if(performanceEvaluationItemsB) {           
            row.XmlData.ItemsB = performanceEvaluationItemsB;  
          }
        }
        if(row.ContentType == 35) { //差旅與交際費用申請單
          row.XmlData.EmployeeSignature = row.XmlData.EmployeeName;
          var entertainmentItems = EntertainmentItemsTable?.value.getData();
          var travelItems = TravelItemsTable?.value.getData();
          if(entertainmentItems) {           
            row.XmlData.EntertainmentItems = entertainmentItems;  
          }
          if(travelItems) {
            row.XmlData.TravelItems = travelItems;  
          }
        }
        if(row.ContentType == 43) { //製作樣品通知單項目
          var sampleNotificationFormItems = SampleNotificationFormItemsTable?.value.getData();
          if(sampleNotificationFormItems) {
            row.XmlData.Items = sampleNotificationFormItems;  
          }
        }
        if(row.ContentType == 50) { //出差工作日報表
          var businessTripDailyReportItems = BusinessTripDailyReportItemsTable?.value.getData();
          if(businessTripDailyReportItems)
            row.XmlData.Items = businessTripDailyReportItems;
        }
      }
      submit();
    }

    onMounted(() => {
      selectedType.value = 2;
    })

    /** 資訊需求申請使用 */
    const typesSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(Object.values(model!.enums.ITRequirementItem).map(e => { return { Name: e.Name, Id: e.Value } }).find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: Object.values(model!.enums.ITRequirementItem).map(e => { return { Name: e.Name, Id: e.Value } }), totalCount: 1 }));
        },
      },
    };
    const reasonsSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(Object.values(model!.enums.ITRequirementICauseReason).map(e => { return { Name: e.Name, Id: e.Value } }).find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: Object.values(model!.enums.ITRequirementICauseReason).map(e => { return { Name: e.Name, Id: e.Value } }), totalCount: 1 }));
        },
      },
    };
    const annexSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(Object.values(model!.enums.ITRequirementIAnnex).map(e => { return { Name: e.Name, Id: e.Value } }).find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: Object.values(model!.enums.ITRequirementIAnnex).map(e => { return { Name: e.Name, Id: e.Value } }), totalCount: 1 }));
        },
      },
    };

    const wrokOrderItemsSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(Object.values(model!.enums.WorkOrderType).map(e => { return { Name: e.Name, Id: e.Value } }).find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: Object.values(model!.enums.WorkOrderType).map(e => { return { Name: e.Name, Id: e.Value } }), totalCount: 1 }));
        },
      },
    };

    const applicationTypesSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(Object.values(model!.enums.PaymentApplicationType).map(e => { return { Name: e.Name, Id: e.Value } }).find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: Object.values(model!.enums.PaymentApplicationType).map(e => { return { Name: e.Name, Id: e.Value } }), totalCount: 1 }));
        },
      },
    };

    const purchaseRequisitionTypeSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(Object.values(model!.enums.PurchaseRequisitionType).map(e => { return { Name: e.Name, Id: e.Value } }).find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: Object.values(model!.enums.PurchaseRequisitionType).map(e => { return { Name: e.Name, Id: e.Value } }), totalCount: 1 }));
        },
      },
    };

    const jobTickeSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "JobTickeNo",
      valueField: "Id",
      columns: [
        {
          field: "JobTickeNo",
          title: "工單號碼",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("jobTicke/find", value), // eslint-disable-line
        query: params => {
          // params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          return model!.dispatch("jobTicke/query", params) // eslint-disable-line
        }
      }
    };

    const customerSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("customer/find", value), // eslint-disable-line
        query: params => {
          // params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          return model!.dispatch("customer/query", params) // eslint-disable-line
        }
      }
    };

    /** 權限開放申請單使用 */
    const requestContentSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(Object.values(model!.enums.PermissionRequestContent).map(e => { return { Name: e.Name, Id: e.Value } }).find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: Object.values(model!.enums.PermissionRequestContent).map(e => { return { Name: e.Name, Id: e.Value } }), totalCount: 1 }));
        },
      },
    };

    /** 物性檢測申請表使用 */
    const physicalPropertiesTestingSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(Object.values(model!.enums.TestingItem).map(e => { return { Name: e.Name, Id: e.Value } }).find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: Object.values(model!.enums.TestingItem).map(e => { return { Name: e.Name, Id: e.Value } }), totalCount: 1 }));
        },
      },
    };

    /** 來料檢驗使用 */
    const categorySelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("iqcCategory/find", value), // eslint-disable-line
        query: params => {
          params.condition = new Condition("Published", Operator.Equal, true).and(params.condition!);
          params.sortings!.push({ column: 'Ordinal', order: 0 });
          return model!.dispatch("iqcCategory/query", params) // eslint-disable-line
        }
      }
    };

    const fileGrid = ref<any>()
    const fileModal = reactive({
      visible: false,
      selectedRow: {} as any,
      uploadOptions: {
        promises: {
          async query() {
            var condition = new Condition("DocumentId", Operator.Equal, fileModal.selectedRow.Id.toString());
            const sortings = [{ column: "CreatedTime", order: 0 }];
            return await model?.dispatch('files/query', {
              condition: condition,
              sortings: sortings,
            });
          },
          insert(formData: FormData) {
            return model?.dispatch('files/insert', { formData, mode: 'other', entityId: 'Document_' + fileModal.selectedRow.Id, fileType: 2 });
          },
          delete(id: number) {
            return model?.dispatch('files/delete', id);
          }
        }
      },
      show(row: any) {
        fileModal.selectedRow = row;
        if (fileGrid.value) fileGrid.value.refresh();
      }
    })

    const problemContentType = [
      // 人(人員)、機(機械/機台)、料(原物料)、法(製程方法)、環(環境因素)、其他
      { Id: '1', Name: '人(人員)' },
      { Id: '2', Name: '機(機械/機台)' },
      { Id: '3', Name: '料(原物料)' },
      { Id: '4', Name: '法(製程方法)' },
      { Id: '5', Name: '環(環境因素)' },
      { Id: '6', Name: '其他' },
    ]
    const problemContentTypeSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(problemContentType.find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: problemContentType, totalCount: 1 }));
        },
      },
    };

    const remedialType = [
      // 人(人員)、機(機械/機台)、料(原物料)、法(製程方法)、環(環境因素)、其他
      { Id: '1', Name: '人(人員)' },
      { Id: '2', Name: '機(機械/機台)' },
      { Id: '3', Name: '料(原物料)' },
      { Id: '4', Name: '法(製程方法)' },
      { Id: '5', Name: '環(環境因素)' },
      { Id: '6', Name: '其他' },
    ]
    const remedialTypeSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(remedialType.find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: remedialType, totalCount: 1 }));
        },
      },
    };

    const handling = [
      { Id: '1', Name: '要求改善' },
      { Id: '2', Name: '客訴索賠' },
      { Id: '3', Name: '其它' },
    ]
    const handlingSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(handling.find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: handling, totalCount: 1 }));
        },
      },
    };

    const responsibilityUnit = [
      { Id: '1', Name: '分條' },
      { Id: '2', Name: '覆卷' },
      { Id: '3', Name: '裁切' },
      { Id: '4', Name: '包裝' },
      { Id: '5', Name: '倉管' },
      { Id: '6', Name: '品管' },
      { Id: '7', Name: '供應商' },
      { Id: '8', Name: '業務' },
      { Id: '9', Name: '採購' },
      { Id: '10', Name: '生管' },
      { Id: '11', Name: '其它' },
    ]
    const responsibilityUnitSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(responsibilityUnit.find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: responsibilityUnit, totalCount: 1 }));
        },
      },
    };

    const contactDepartment = [
      { Id: '1', Name: 'A.業務部' },
      { Id: '2', Name: 'B.管理部' },
      { Id: '3', Name: 'C.生管組' },
      { Id: '4', Name: 'D.品管組' },
      { Id: '5', Name: 'E.分條組' },
      { Id: '6', Name: 'F.手動分條組' },
      { Id: '7', Name: 'G.切卷組' },
      { Id: '8', Name: 'H.包裝組' },
      { Id: '9', Name: 'I.切管組' },
      { Id: '10', Name: 'J.倉庫組' },
      { Id: '11', Name: 'K.廠務組' },
      { Id: '12', Name: 'L.採購組' },
    ]
    const contactDepartmentSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(contactDepartment.find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: contactDepartment, totalCount: 1 }));
        },
      },
    };

    const messageType = [
      { Id: '1', Name: '一般訊息溝通' },
      { Id: '2', Name: '急件' },
      { Id: '3', Name: '需求' },
      { Id: '4', Name: '通知' },
    ]
    const messageTypeSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(messageType.find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: messageType, totalCount: 1 }));
        },
      },
    };

    return {
      grid,
      form,
      gridOptions,
      formOptions,
      canCreate,
      userSelectorOptions,
      departmentSelectorOptions,
      selectedType,
      model,
      initAddData,
      onGridEdit,
      itemsTable,
      formSubmit,
      readType,
      annexSelectorOptions,
      reasonsSelectorOptions,
      typesSelectorOptions,
      wrokOrderItemsSelectorOptions,
      ProductModifyItemsTable,
      ReRollModifyItemsTable,
      FinishedProductModifyItemsTable,
      MaterialSubstitutesItemsTable,
      applicationTypesSelectorOptions,
      AssessmentItemsTable,
      ProductNumberModifuItemsTable,
      PurchaseRequisitionItemsTable,
      purchaseRequisitionTypeSelectorOptions,
      OrderItemsTable,
      CustomerCreditItemsTable,
      CheckRecordItemsTable,
      IQCItemsTable,
      TypeAProductionCheckItemsTable,
      WinderItemsTable,
      RollItemsTable,
      ProductionPackageQCItemsTable,
      FinalQualityInspectionReportItemsTable,
      SalesItemsTable,
      TrackingItemsTable,
      StaffItemsTable,
      HumidityCheckRecordItemsTable,
      JobTickeDocumentItemsTable,
      RemittanceSlipItemsTable,
      jobTickeSelectorOptions,
      customerSelectorOptions,
      requestContentSelectorOptions,
      PerformanceEvaluationItemsATable,
      PerformanceEvaluationItemsBTable,
      EntertainmentItemsTable,
      TravelItemsTable,
      physicalPropertiesTestingSelectorOptions,
      SampleNotificationFormItemsTable,
      categorySelectorOptions,
      invoice,
      packingList,
      formatDate,
      formatAmount,
      fileModal,
      fileGrid,
      remedialTypeSelectorOptions,
      problemContentTypeSelectorOptions,
      handlingSelectorOptions,
      responsibilityUnitSelectorOptions,
      contactDepartmentSelectorOptions,
      messageTypeSelectorOptions,
    }
  },
  methods: {
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    }
  }
})
</script>
